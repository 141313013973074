<template>
  <div class="d-flex d-flex flex-column vh100">
    <div class="pb flex-grow-1">
      <div class="ml-text-20-32-600 mb-4 d-flex justify-content-between">
        Время отправки
        <v-btn icon large text class="ml-close-btn-friend " @click="goBack">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!-- <a href="#" @click.prevent="goBack">
          <img src="@/assets/img/close.svg" alt="" />
        </a> -->
      </div>
      <div>
        <MlCalendar v-model="date" @select-date="selectDate" class="mb-5" />
      </div>
      <div>
        <v-select
          class="ml-select ml-hide-details"
          v-model="time"
          :items="times"
          outlined
          return-object
          color="dark"
          @change="selectTime"
        >
          <template #selection="{item}">
            <div class="d-flex w100 justify-content-between">
              <div class="ml-select-time-item_time" v-if="item.time">
                {{ item.time }}
              </div>
              <div class="ml-select-time-item_text">{{ item.text }}</div>
            </div>
          </template>
          <template #item="{item}">
            <!-- <div :style="{ paddingLeft: typeId === item.id ? '0px' : '30px' }">
              <v-icon v-if="typeId === item.id">mdi-check</v-icon>
              {{ item.name }}
            </div> -->
            <div class="d-flex w100 justify-content-between">
              <div class="ml-select-time-item_time" v-if="item.time">
                {{ item.time }}
              </div>
              <div class="ml-select-time-item_text">{{ item.text }}</div>
            </div>
          </template>
        </v-select>
      </div>
      <!-- <div v-if="isManualTime"> -->
      <!-- <MlInputTime
          @select-manual="selectManual"
          :active="isManualTime"
          class="mb-2"
          ref="MlInputTime"
        /> -->
      <!-- <div class="mb-9">
          <div
            class="ml-select-time-item mb-2"
            v-for="(item, index) in times"
            :key="index"
            @click.prevent="selectTime(item)"
            :class="{ active: isActiveTime(item) }"
          >
            <div class="ml-select-time-item_time" v-if="item.time">
              {{ item.time }}
            </div>
            <div class="ml-select-time-item_text">{{ item.text }}</div>
          </div>
        </div> -->
      <!-- </div> -->
    </div>
    <div class="controlls" ref="controlls">
      <button @click.prevent="save" class="ml-black-btn w100">
        Применить
      </button>
    </div>
  </div>
</template>

<script>
import Constants from '@/helpers/constants'
import MlCalendar from '@/components/UI/MlCalendar'
// import MlInputTime from '@/components/UI/MlInputTime'
import orderTypes from '@/store/order/types'
import { mapActions, mapMutations } from 'vuex'
import MixinObserveElement from '@/helpers/mixins/observeElement'
import { format } from 'date-fns'
import appTypes from '@/store/app/types'

const timeNow = {
  time: null,
  text: 'Сейчас',
  value: Constants?.TIME_TO_SEND?.NOW
}

const timeAny = {
  time: '00:00 - 23:59',
  text: 'В любое время',
  value: Constants?.TIME_TO_SEND?.ANY
}

// const timeManual = {
//   time: null,
//   text: 'В указанное время',
//   value: Constants?.TIME_TO_SEND?.MANUAL
// }

export default {
  // props: {
  //   dateProp: String,
  //   timeProp: Object,
  //   basketId: String
  // },
  mixins: [MixinObserveElement],
  components: {
    MlCalendar
    // MlInputTime
  },
  data: () => ({
    // selectedTime: null,
    date: null,
    time: null,
    basketId: null,
    routeAfterSave: null,
    routeAfterSaveDefault: 'FriendContact',
    // prettier-ignore
    times: [
      // timeManual,
      timeAny,
      { time: '10:00 - 12:00', text: 'С утра', value: Constants?.TIME_TO_SEND?.MORNING, timeForApi: '10:00' },
      { time: '12:00 - 14:00', text: 'В обед', value: Constants?.TIME_TO_SEND?.LUNCH, timeForApi: '12:00' },
      { time: '18:00 - 20:00', text: 'Вечером', value: Constants?.TIME_TO_SEND?.EVENING, timeForApi: '18:00' },
      // { time: null, text: 'Сейчас', value: Constants?.TIME_TO_SEND?.NOW },
      timeNow
    ]
  }),
  computed: {
    // isManualTime() {
    //   return this.time?.value === Constants?.TIME_TO_SEND?.MANUAL
    // },
    observedElement() {
      return this.$refs.controlls
    }
  },
  mounted() {
    this.setInitialize()
  },
  beforeDestroy() {
    window?.xprops?.onHide()
  },
  methods: {
    ...mapActions('order', [orderTypes.SET_FRIEND_DELIVERY_DATETIME_ACTION]),
    ...mapMutations('app', [appTypes.SET_OPACITY]),
    goBack() {
      this.$router.push({
        name: this.basketId ? this.routeAfterSave : this.routeAfterSaveDefault
      })
    },
    setInitialize() {
      window?.xprops?.onHide()
      const {
        routeAfterSave,
        basketId,
        dateProp,
        timeProp
      } = this.$route?.params
      this.routeAfterSave = routeAfterSave || null
      this.basketId = basketId || null
      this.date = dateProp
      this.time = timeProp
      // this.selectedTime = timeProp
      if (!dateProp && !timeProp) {
        this.time = timeNow
        this.date = format(new Date(), 'yyyy-MM-dd')
        // this.selectedTime = timeNow
      }
      // if (this.time?.value === this.$const.TIME_TO_SEND?.MANUAL) {
      //   this.$refs.MlInputTime.setValue(this.time?.time)
      // }
      this.handleScroll()
    },
    // selectManual(value = null) {
    //   const data = {
    //     text: 'В указанное время',
    //     time: null,
    //     value: Constants?.TIME_TO_SEND?.MANUAL
    //   }
    //   if (value) {
    //     data.time = value
    //   }
    //   this.time = data
    // },
    // isActiveTime(item) {
    //   return this.time?.value === item?.value
    // },
    selectDate(date) {
      this.date = date
      if (this.time?.value === timeNow?.value) {
        this.time = timeAny
      }
    },
    selectTime(time) {
      if (time?.value === Constants?.TIME_TO_SEND?.NOW) {
        this.date = format(new Date(), 'yyyy-MM-dd')
      } else {
        if (!this.date) {
          this.date = format(new Date(), 'yyyy-MM-dd')
        }
      }
      this.$set(this, 'time', time)
      // this.$set(this, 'selectedTime', time)
    },
    save() {
      this[appTypes.SET_OPACITY](0)
      setTimeout(() => {
        this[orderTypes.SET_FRIEND_DELIVERY_DATETIME_ACTION]({
          date: this.date,
          time: this.time,
          id: this.basketId
        })
        this.$router.push({
          name: this.basketId ? this.routeAfterSave : this.routeAfterSaveDefault
        })
      }, 250)
    }
  }
}
</script>
